<template>
    <section-loading v-if="isLoading" />
    <v-card
        v-else
        class="mb-2"
        :class="{ 'px-5 py-4 mb-5': $vuetify.breakpoint.mdAndUp }"
    >
        <v-row class="ma-0">
            <v-col cols="12" lg="9" xl="6" class="pa-0">
                <a-form :submit="save">
                    <v-card-title class="mb-4">
                        Personal Information
                    </v-card-title>

                    <v-card-text>
                        <v-row dense>
                            <v-col cols="12" md="6">
                                <a-text-input
                                    v-model="firstname"
                                    label="First Name"
                                    rules="required"
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                <a-text-input
                                    v-model="lastname"
                                    label="Last Name"
                                    rules="required"
                                />
                            </v-col>
                            <v-col cols="12">
                                <a-text-input
                                    v-model="email"
                                    label="Email"
                                    rules="required|email"
                                />
                            </v-col>
                            <v-col cols="12">
                                <a-text-input
                                    v-model="original_email"
                                    label="Paypal Email"
                                    rules="required|email"
                                />
                            </v-col>
                            <v-col cols="12">
                                <a-phone-input
                                    v-model="phone"
                                    rules="required"
                                    label="Phone Number"
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-autocomplete
                                    v-model="timezone"
                                    filled
                                    label="Time Zone"
                                    :items="timezones"
                                    item-value="index"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions class="mx-2 pb-4 pt-0">
                        <v-btn
                            type="submit"
                            color="primary"
                            :loading="isSaving"
                            :block="$vuetify.breakpoint.smAndDown"
                        >
                            Save
                        </v-btn>
                    </v-card-actions>
                </a-form>
            </v-col>
        </v-row>
    </v-card>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import SectionLoading from './SectionLoading.vue';

import { AForm } from '@/components/AForm';
import { ATextInput } from '@/components/AForm/Inputs/ATextInput';
import { APhoneInput } from '@/components/AForm/Inputs/APhoneInput';

@Component({
    components: {
        AForm,
        APhoneInput,
        ATextInput,
        SectionLoading
    }
})
export default class PersonalInformation extends Vue {
    firstname = '';
    lastname = '';
    email = '';
    original_email = '';
    phone = '';
    timezone = '';

    timezones = [];

    isLoading = true;
    isSaving = false;

    mounted() {
        this.setLoading();

        return this.getUserData()
            .then(({ data }) => {
                if (data) {
                    const {
                        firstname,
                        lastname,
                        email,
                        original_email,
                        phone,
                        timezone
                    } = data.user;

                    const { zones } = data;

                    this.firstname = firstname;
                    this.lastname = lastname;
                    this.email = email;
                    this.original_email = original_email;
                    this.phone = phone;
                    this.timezone = timezone;
                    this.timezones = zones;
                }
            })
            .finally(this.setLoading.bind(this, false));
    }

    setLoading(isLoading = true) {
        this.$emit('loading', isLoading);
        this.isLoading = isLoading;
    }

    setSaving(isSaving = true) {
        this.$emit('saving', isSaving);
        this.isSaving = isSaving;
    }

    getUserData() {
        return this.$http
            .get('/users/panel')
            .then(({ data }) => data)
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }

                return {};
            });
    }

    setUserData() {
        return this.$http
            .post('/users/panel', this.getDataToSave())
            .then(({ data }) => data)
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }

                return {};
            });
    }

    getDataToSave() {
        return (({
            firstname,
            lastname,
            email,
            original_email,
            phone,
            timezone
        }) => ({
            firstname,
            lastname,
            email,
            original_email,
            phone,
            timezone
        }))(this);
    }

    save() {
        this.setSaving();

        return this.setUserData()
            .then(data => {
                if (!data.meta.success) {
                    this.$store.dispatch(
                        'notification/error',
                        'Unable to save Personal Information. Please check the form for errors.'
                    );

                    return false;
                }

                this.onSave();

                this.$store.dispatch(
                    'notification/success',
                    'Personal Information updated'
                );
            })
            .finally(this.setSaving.bind(this, false));
    }

    onSave() {
        this.$emit('updated', this.getDataToSave());
    }
}
</script>
