<template>
    <router-view v-if="isEditing" />
    <section-loading v-else-if="isLoading" />
    <v-card
        v-else
        class="mb-2"
        :class="{ 'px-5 py-4 mb-5': $vuetify.breakpoint.mdAndUp }"
    >
        <v-row class="ma-0">
            <v-col cols="12" class="pa-0">
                <v-card-title>
                    Companies
                    <v-spacer />
                    <v-btn
                        class="primary mb-n1"
                        title="Add Company"
                        @click="create"
                    >
                        <v-icon :left="$vuetify.breakpoint.mdAndUp" small>
                            plus
                        </v-icon>
                        {{ $vuetify.breakpoint.mdAndUp ? 'Add Company' : null }}
                    </v-btn>
                </v-card-title>
                <v-card-text :class="{ 'px-0': $vuetify.breakpoint.mdAndUp }">
                    <companies-client-selector
                        v-if="isReseller"
                        :clients="clients"
                    />

                    <Most-used :companies="mostUsed" @select="edit" />

                    <companies-filter :user-id="userId" />

                    <companies-list
                        :companies="companies"
                        @create="create"
                        @edit="edit"
                        @drop="drop"
                    />

                    <paging
                        :total="total"
                        :default-per-page="perPage"
                        class="px-4"
                    />

                    <company-delete-dialog
                        id="confirm-delete-company"
                        :company="confirmCompany"
                        @confirm="confirmDelete"
                    />
                </v-card-text>
            </v-col>
        </v-row>
    </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

import SectionLoading from '@/pages/Profile/sections/SectionLoading.vue';
import CompaniesClientSelector from './CompaniesClientSelector.vue';
import { Paging } from '@/components/Paging';

import MostUsed from './MostUsed.vue';
import CompaniesFilter from './CompaniesFilter.vue';
import CompaniesList from './CompaniesList.vue';
import CompanyDeleteDialog from './CompanyDeleteDialog.vue';

import type { Company } from '@/types/Company';
import type { ClientUser } from '@/types/User';

@Component({
    components: {
        SectionLoading,
        CompaniesClientSelector,
        CompaniesList,
        CompaniesFilter,
        MostUsed,
        Paging,
        CompanyDeleteDialog
    },
    computed: {
        ...mapGetters('user', ['isReseller'])
    }
})
export default class Companies extends Vue {
    isReseller!: boolean;

    isLoading = true;
    confirmCompany: Partial<Company> = {};

    companies: Company[] = [];

    total = 0;

    perPage = 10;

    mostUsed: Company[] = [];

    clients: ClientUser[] = [];

    get queryParams() {
        return {
            user_id: this.userId,
            limit: this.perPage,
            page: 1,
            sort: 'name',
            asc: 1,
            term: ''
        };
    }

    get userId() {
        return (
            (Array.isArray(this.$route.query.user_id)
                ? this.$route.query.user_id[0]
                : this.$route.query.user_id) || ''
        );
    }

    get isEditing() {
        return (
            Boolean(Number(this.$route.params.companyId)) ||
            this.$route.params.companyId === 'create'
        );
    }

    get endpoint() {
        return ['/companies', this.query].filter(Boolean).join('?');
    }

    get query() {
        return Object.entries(this.queryParams)
            .map(([key, value]) => {
                if (typeof this.$route.query[key] !== 'undefined') {
                    value = String(this.$route.query[key]);
                }

                if (value) {
                    return `${key}=${encodeURIComponent(value)}`;
                }

                return false;
            })
            .filter(Boolean)
            .join('&');
    }

    created() {
        this.$watch(
            () => this.$route.query,
            () => {
                this.update();
            }
        );
    }

    mounted() {
        this.setLoading();

        this.update().finally(this.setLoading.bind(this, false));
    }

    setLoading(isLoading = true) {
        this.isLoading = isLoading;
    }

    async update() {
        return this.getData().then(({ data }) => {
            if (data) {
                this.companies = data.companies;
                this.total = data.length;

                this.mostUsed = data.mostUsed;

                this.clients = data.users;
            }
        });
    }

    async getData() {
        return this.$http
            .get(this.endpoint)
            .then(({ data }) => data)
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }

                return {};
            });
    }

    create() {
        this.$router.push(
            ['companies/create', this.userId ? `?user_id=${this.userId}` : '']
                .filter(Boolean)
                .join('')
        );
    }

    edit(company: Company) {
        this.$router.push(`companies/${company.id}`);
    }

    drop(company: Company) {
        this.confirmCompany = company;

        this.$store.dispatch('modal/open', 'confirm-delete-company');
    }

    confirmDelete() {
        if (this.confirmCompany.id) {
            this.deleteCompanyConfirmed(this.confirmCompany.id);
        }

        this.$store.dispatch('modal/close', 'confirm-delete-company');
    }

    deleteCompanyConfirmed(id: number) {
        if (!id) {
            return;
        }

        const url = `/companies/delete/${id}`;

        return this.$http
            .delete(url)
            .then(({ data }) => {
                const { success, error } = data.meta;

                if (success) {
                    this.$store.dispatch('notification/success', success);
                    // reload the list o companies
                    this.update();
                } else {
                    this.$store.dispatch('notification/error', error);
                }
            })
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }
            });
    }
}
</script>
