<template>
    <modal id="become-affiliate-form-modal" title="Become an Affiliate">
        <v-container class="pl-0">
            <a-form :submit="becomeAnAffiliate" :disabled="isBusy">
                <v-row>
                    <v-col cols="12" sm="10" class="pb-0">
                        <span>
                            Partner with AmpiFire to recommend our services at
                            special rates and earn a commission through our
                            affiliate program.
                        </span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="10" class="pb-0">
                        <a-text-input
                            v-model="email"
                            label="Email"
                            rules="required|email"
                            html-hint
                            hint="Please provide a unique email address, that has not been used previously. We will send your affiliate commissions via <strong>PayPal</strong> to this email address"
                            placeholder="Provide a valid PayPal email"
                            persistent-hint
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="10">
                        <validation-provider
                            v-slot="{ validated }"
                            rules="required"
                            name="terms"
                        >
                            <v-checkbox
                                v-model="acceptedTerms"
                                :error="
                                    (validated || tosError) && !acceptedTerms
                                "
                            >
                                <template #label>
                                    <div>
                                        By submitting this form, you agree to
                                        our
                                        <router-link
                                            class="d-inline-block"
                                            to="/profile/terms-of-service"
                                            target="_blank"
                                            @click.native="onTermsLinkClick"
                                        >
                                            Terms of Service
                                        </router-link>
                                    </div>
                                </template>
                            </v-checkbox>
                        </validation-provider>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="2"
                        class="d-flex justify-end align-center"
                    >
                        <v-btn
                            type="submit"
                            color="primary"
                            :disabled="isBusy"
                            :loading="isBusy"
                        >
                            Join
                        </v-btn>
                    </v-col>
                </v-row>
            </a-form>
        </v-container>
    </modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { AForm } from '@/components/AForm';
import { ATextInput } from '@/components/AForm/Inputs/ATextInput';
import { Modal } from '@/components/Modal';

import { reduceErrors } from '@/utils/helpers';
import { ValidationProvider } from 'vee-validate';

@Component({
    name: 'BecomeAffiliateFormModal',
    components: {
        AForm,
        ATextInput,
        Modal,
        ValidationProvider
    }
})
export default class BecomeAffiliateFormModal extends Vue {
    email = '';

    isBusy = false;

    endpoint = '/affiliates/become';

    redirect = '/affiliates/thanks?joined=3';

    acceptedTerms = false;

    tosError = false;

    async becomeAnAffiliate() {
        if (!this.acceptedTerms) {
            this.tosError = true;
            return false;
        }
        this.isBusy = true;
        this.$http
            .post(this.endpoint, { email: this.email })
            .then(({ data }) => {
                this.$store.dispatch(
                    'notification/success',
                    `Affiliate account(${data?.data?.affiliate?.email}) created and approved. You will be redirected shortly.`
                );
                window.location.href = this.redirect;
            })
            .catch(error => {
                const errors = new Set<string>();
                reduceErrors(error?.response?.data?.errors, errors);
                this.$store.dispatch(
                    'notification/error',
                    [...errors].join('<br/>')
                );
            })
            .finally(() => {
                this.isBusy = false;
            });
    }

    onTermsLinkClick(e: MouseEvent) {
        e.stopImmediatePropagation();
    }

    onAccept() {
        this.acceptedTerms = true;
    }
}
</script>
