import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[(_vm.$vuetify.breakpoint.mdAndUp)?_c('welcome-banner',{staticClass:"mt-4 mb-7"}):_vm._e(),_c(VRow,{staticClass:"mt-2 mb-0 px-3"},[_c('div',{staticClass:"text-h6 text-md-h5 font-weight-bold"},[_vm._v(" Profile Settings ")]),_c(VSpacer),_c('quick-links',{attrs:{"profile":_vm.profile}})],1),_c(VRow,[_c(VCol,{class:{
                'd-flex justify-center pr-0 pt-0':
                    _vm.$vuetify.breakpoint.smAndDown
            },attrs:{"cols":_vm.$vuetify.breakpoint.xs ? 2 : 1,"md":"3","xl":"2"}},[_c('sections-drawer',{staticClass:"main-background",attrs:{"user":_vm.user,"sections":_vm.sections,"loading":_vm.isLoading,"context":_vm.context}},[_c(VListItem,{staticClass:"justify-center",on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_c(VListItemIcon,{staticClass:"justify-center",class:{ 'mr-3': _vm.$vuetify.breakpoint.mdAndUp }},[_c(VIcon,{staticStyle:{"width":"20px"},attrs:{"small":""}},[_vm._v(" sign-out-alt ")])],1),_c(VListItemContent,[_c(VListItemSubtitle,{staticClass:"secondary--text"},[_vm._v(" Logout ")])],1)],1)],1)],1),_c(VCol,{class:{ 'py-0': _vm.$vuetify.breakpoint.smAndDown },attrs:{"cols":_vm.$vuetify.breakpoint.xs ? 10 : 11,"md":"9","xl":"10"}},[(_vm.card === 'personal')?_c('personal-information',{on:{"updated":_vm.update}}):_vm._e(),(_vm.card === 'security')?_c('security'):_vm._e(),(_vm.card === 'terms-of-service')?_c('terms-of-service'):_vm._e(),(_vm.card === 'credits')?_c('credits',{attrs:{"is-checking-payment":_vm.autoCheckPaymentInterval}}):_vm._e(),(_vm.card === 'payments')?_c('payments'):_vm._e(),(_vm.card === 'payments')?_c('subscriptions'):_vm._e(),(_vm.card === 'whitelabel')?_c('whitelabel'):_vm._e(),(_vm.card === 'companies')?_c('companies'):_vm._e()],1)],1),_c('become-affiliate-form-modal')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }