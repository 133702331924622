import type { QuickLinkData } from '@/types/User';

const LinksPool = {
    admin_reports: {
        href: '/reports/admin',
        title: 'Admin Reports'
    },
    alpha_stacker_training: {
        href: '/training',
        title: 'AlphaStacker Training'
    },
    asigo_sales_funnel_settings: {
        href: '/',
        title: 'Amp Sales Funnel Settings'
    },
    author_requests: {
        to: '/author-requests',
        title: 'Author Requests'
    },
    custom_pricing_link: {
        href: '/resellers/price_override_links',
        title: 'Custom Pricing Link'
    },
    development: {
        href: '/development',
        title: 'Development'
    },
    email_marketing: {
        href: '/email_series/marketing',
        title: 'Email Marketing'
    },
    generated_keyword_rankings: {
        href: '/development/generated_keyword_rankings',
        title: 'Generated Keyword Rankings'
    },
    get_affiliate_links: {
        href: '/affiliates/links',
        title: 'Get Affiliate Links'
    },
    manage_press_releases: {
        href: '/press_releases',
        title: 'Manage Press Releases'
    },
    manage_temporary_sale_pages: {
        href: '/temporary_sale_pages',
        title: 'Manage Temporary Sale Pages'
    },
    manage_users: {
        href: '/users',
        title: `Manage Users`
    },
    mock_interwire_admin: {
        href: 'http://interwire.io/admin',
        title: 'MockInterwire Admin'
    },
    premium_credits_sales_page: {
        to: '/premium',
        title: 'Premium Credits Sales Page'
    },
    defi_credits_sales_page: {
        to: '/defi-distribution',
        title: 'DeFi Distribution Credits Sales Page'
    },
    blow_packs_sales_page: {
        to: '/blow',
        title: 'B.L.O.W. Packs Sales Page'
    },
    msn_sales_page: {
        to: '/msn',
        title: 'MSN Distribution Sales Page'
    },
    press_release_admin: {
        href: '/press_releases/admin',
        title: 'Press Release Admin'
    },
    press_release_authors: {
        to: '/author-requests',
        title: 'Press Release Authors'
    },
    re_check_distribution_links: {
        href: '/press_releases/regenerate_report',
        title: 'Re-Check Distribution Links'
    },
    review_emails: {
        href: '/users/nocustomers',
        title: 'Review Emails'
    },
    training: {
        href: '/training',
        title: 'Training'
    },
    unsubscribe_emails: {
        href: '/users/unsubscribe',
        title: 'Unsubscribe Emails'
    },
    vid_bullet_admin: {
        href: '/video_prs/admin',
        title: 'VidBullet Admin'
    },
    video_approval: {
        to: '/admin/videos/approval',
        title: 'Video approval'
    },
    blog_post_approval: {
        to: '/admin/blog-posts/approval',
        title: 'Blog Post approval'
    },
    search_news_articles: {
        to: '/admin/articles/search',
        title: 'Search News Articles'
    },
    become_an_affiliate: {
        title: 'Become an Affiliate',
        modal_id: 'become-affiliate-form-modal'
    },
    affiliate_login: {
        title: 'Affiliate Login',
        href: 'https://affiliates.presscable.com/users/login'
    }
};

export default LinksPool as {
    [Property in keyof typeof LinksPool]: QuickLinkData;
};
