import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('modal',{attrs:{"id":"become-affiliate-form-modal","title":"Become an Affiliate"}},[_c(VContainer,{staticClass:"pl-0"},[_c('a-form',{attrs:{"submit":_vm.becomeAnAffiliate,"disabled":_vm.isBusy}},[_c(VRow,[_c(VCol,{staticClass:"pb-0",attrs:{"cols":"12","sm":"10"}},[_c('span',[_vm._v(" Partner with AmpiFire to recommend our services at special rates and earn a commission through our affiliate program. ")])])],1),_c(VRow,[_c(VCol,{staticClass:"pb-0",attrs:{"cols":"12","sm":"10"}},[_c('a-text-input',{attrs:{"label":"Email","rules":"required|email","html-hint":"","hint":"Please provide a unique email address, that has not been used previously. We will send your affiliate commissions via <strong>PayPal</strong> to this email address","placeholder":"Provide a valid PayPal email","persistent-hint":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"10"}},[_c('validation-provider',{attrs:{"rules":"required","name":"terms"},scopedSlots:_vm._u([{key:"default",fn:function({ validated }){return [_c(VCheckbox,{attrs:{"error":(validated || _vm.tosError) && !_vm.acceptedTerms},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" By submitting this form, you agree to our "),_c('router-link',{staticClass:"d-inline-block",attrs:{"to":"/profile/terms-of-service","target":"_blank"},nativeOn:{"click":function($event){return _vm.onTermsLinkClick.apply(null, arguments)}}},[_vm._v(" Terms of Service ")])],1)]},proxy:true}],null,true),model:{value:(_vm.acceptedTerms),callback:function ($$v) {_vm.acceptedTerms=$$v},expression:"acceptedTerms"}})]}}])})],1),_c(VCol,{staticClass:"d-flex justify-end align-center",attrs:{"cols":"12","sm":"2"}},[_c(VBtn,{attrs:{"type":"submit","color":"primary","disabled":_vm.isBusy,"loading":_vm.isBusy}},[_vm._v(" Join ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }