import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isLoading)?_c('section-loading'):_c(VCard,{staticClass:"mb-2",class:{ 'px-5 py-4 mb-5': _vm.$vuetify.breakpoint.mdAndUp }},[_c(VRow,{staticClass:"ma-0"},[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12","lg":"9","xl":"6"}},[_c('a-form',{attrs:{"submit":_vm.save}},[_c(VCardTitle,{staticClass:"mb-4"},[_vm._v(" Personal Information ")]),_c(VCardText,[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('a-text-input',{attrs:{"label":"First Name","rules":"required"},model:{value:(_vm.firstname),callback:function ($$v) {_vm.firstname=$$v},expression:"firstname"}})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('a-text-input',{attrs:{"label":"Last Name","rules":"required"},model:{value:(_vm.lastname),callback:function ($$v) {_vm.lastname=$$v},expression:"lastname"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('a-text-input',{attrs:{"label":"Email","rules":"required|email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('a-text-input',{attrs:{"label":"Paypal Email","rules":"required|email"},model:{value:(_vm.original_email),callback:function ($$v) {_vm.original_email=$$v},expression:"original_email"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('a-phone-input',{attrs:{"rules":"required","label":"Phone Number"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VAutocomplete,{attrs:{"filled":"","label":"Time Zone","items":_vm.timezones,"item-value":"index"},model:{value:(_vm.timezone),callback:function ($$v) {_vm.timezone=$$v},expression:"timezone"}})],1)],1)],1),_c(VCardActions,{staticClass:"mx-2 pb-4 pt-0"},[_c(VBtn,{attrs:{"type":"submit","color":"primary","loading":_vm.isSaving,"block":_vm.$vuetify.breakpoint.smAndDown}},[_vm._v(" Save ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }